<template>
  <div>
    <pcHeaders></pcHeaders>
    <div class="namms">
      <div>
        <div class="suc">
          <div><img src="../../assets/images/pay_03.png"
                 alt=""></div>
          <div style="font-size: 22px;color: #ff9f53;margin-top: 22px;">恭喜您，支付成功</div>
        </div>

        <div class="payDetail">
          <div class="payHead">
            <div class="su"></div>
            <div style="font-weight: bold;">支付详情</div>
          </div>
          <div class="list">
            <div>运单编号</div>
            <div>{{ orderList.expressNo }}</div>
          </div>
          <div class="list">
            <div>支付时间</div>
            <div>{{ orderList.expressPaytime }}</div>
          </div>
          <div class="list">
            <div>支付方式</div>
            <div>
              {{ orderList.expressPaytype =='01' ? '在线支付' :  orderList.expressPaytype =='02' ? '学习帐户' :orderList.expressPaytype =='03' ? '现金面付' :orderList.expressPaytype =='04' ? '银行汇款' :orderList.expressPaytype =='05' ? '邮局汇款' :orderList.expressPaytype =='06' ? '支付宝'  :orderList.expressPaytype =='07' ? '手机支付(支付宝)' :orderList.expressPaytype =='08' ? '刷卡支付' :orderList.expressPaytype =='00' ? '其它' :orderList.expressPaytype =='0901' ? '微信公众号' :orderList.expressPaytype =='0902' ? '微信WAP' :orderList.expressPaytype =='0903' ? '微信扫码' :orderList.expressPaytype =='0904' ? '微信APP' :orderList.expressPaytype =='10' ? '手工' :orderList.expressPaytype =='11' ? '银联支付' :orderList.expressPaytype =='12' ? '手机银联' :orderList.expressPaytype =='13' ? '未到账' :''}}
            </div>
          </div>
          <div class="list">
            <div>支付金额</div>
            <div style="color: #ff9f53;">￥{{ orderList.expressPrice }}</div>
          </div>
        </div>
      </div>
    </div>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { getExpressDetail } from '@/api/cert.js';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      orderList: {},
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goStudy() {
      this.$router.push('/studyMain')
    },
    getList() {
      getExpressDetail({ expressId: this.$route.query.orderId }).then(res => {
        this.orderList = res.data.data
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.namms {
  display: flex;
  justify-content: center;
  padding: 30px;

  .suc {
    width: 1200px;
    height: 198px;
    border: 1px solid #ebebeb;
    box-shadow: 0 0 6px #ccc;
    margin-top: 22px;
    text-align: center;
    padding-top: 45px;
  }

  .payDetail {
    width: 1200px;
    margin-top: 16px;
    .payHead {
      padding: 0 45px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #666;
      height: 54px;
      border-top: 1px solid #d0d0d0;
      border-left: 1px solid #d0d0d0;
      border-right: 1px solid #d0d0d0;
      .su {
        width: 5px;
        height: 22px;
        border-radius: 4px;
        background: #ffa45b;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px dashed #d0d0d0;
      height: 40px;
      background: #f7f7f7;
      padding: 0 45px;
      font-size: 14px;
      color: #999999;
    }
    .tables {
      width: 100%;
      display: flex;
      justify-content: space-around;
      font-size: 16px;
      border: 1px solid #d0d0d0;
      border-top: 0;
      min-height: 60px;

      div {
        border-right: 1px solid #d0d0d0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25%;
      }
      div:nth-child(5) {
        border: 0 !important;
      }
      .sudyBtn {
        width: 50% !important;
        height: 25px;
        color: #ff9f53;
        border-radius: 3px;
        line-height: 25px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        border: 0 !important;
      }
    }
  }
}
</style>